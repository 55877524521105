const palette = {
  background: {
    default: '#fff',
  },
  text: {
    primary: '#17252A',
    secondary: '#17252A',
  },
  primary: {
    light: '#17252A',
    main: '#17252A',
    dark: '#17252A',
    contrastText: '#fff',
    colorTheme: '#3A3A3A',
  },
  secondary: {
    light: '#6affff',
    main: '#3AAFA9',
    dark: '#00a7bc',
    contrastText: '#fff',
  },
  button: {
    primary: '#172629',
    brown: '#606060',
  },
  footHover: '#337ab7',
  notifications: {
    light: '#5a5959',
    main: '#5a5959',
    dark: '#5a5959',
    contrastText: '#fff',
  },
  footer: {
    background: '#D8D8D8',
  },
  hover: {
    tawny: '#F8AA3D',
  },
};

export default palette;
